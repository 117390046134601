.mainProjectsDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.cardHTML {
  padding: 0 10px;
  flex-basis: calc(50% - 20px);
  margin-bottom: 20px;
}
/* @media screen and (max-width: 960px) {
  .cardHTML {
    flex-basis: calc(50% - 20px);
  }
} */
@media screen and (max-width: 580px) {
  .cardHTML {
    flex-basis: calc(100% - 20px);
  }
}
.cardHTML,
.cardBody {
  height: 100%;
}
.blog-card {
  max-width: 550px;
  width: 100%;
  height: 500px;
  position: relative;
  color: #fff;
  top: 20%;
  right: 0;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: all 0.4s;
  background: url(https://unsplash.it/600/800?image=1061) center no-repeat;
  /* background-size: auto; */
  background-size: 120%;
  border-radius: 10px;
}
.blog-card a {
  /* color: green; */
  /* color: #232427; */
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s;
  /* font-size: 1.8rem; */
  /*font-size: 1.8rem;
    color: #fff;

    transition: 0.5s;
    margin-bottom: 15px;*/
}
.blog-card .color-overlay {
  background: rgba(64, 84, 94, 0.5);
  width: 550px;
  height: 500px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card .gradient-overlay {
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 550px;
  height: 500px;
  position: absolute;
  top: 350px;
  left: 0;
  z-index: 3;
}
.blog-card:hover {
  box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card:hover .card-info {
  opacity: 1;
  bottom: 100px;
}
.blog-card:hover .color-overlay {
  background: rgba(64, 64, 70, 0.8);
}
.blog-card:hover .title-content {
  margin-top: 30px;
}
.title-content {
  text-align: center;
  margin: 170px 0 0 0;
  position: absolute;
  z-index: 4;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.6s;
  font-weight: bold;
}

.blog-card:hover h3:after {
  animation: changeLetter 0.3s 1 linear;
  width: 80%;
}

.blog-card h3,
h1 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: inline-block;
}
.blog-card h3 a {
  /* text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  transition: all 0.2s;
}
.blog-card h3 a:hover {
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}
h3:after {
  content: " ";
  display: block;
  width: 10%;
  height: 2px;
  margin: 20px auto;
  border: 0;
  background: #bda26b;
  transition: all 0.2s;
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro {
  width: 170px;
  margin: 0 auto;
  /* color: #ddd; */
  font-style: italic;
  line-height: 18px;
}
.intro a {
  color: #ddd;
}
.intro a:hover {
  text-decoration: underline;
}
.card-info {
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: -40px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 20px;
  z-index: 20;
  opacity: 0;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info a {
  display: block;
  width: 100px;
  margin: 15px auto;
  background: #fff;
  color: #444;
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 0.8em;
}
.card-info a:hover {
  background: #8e7c49;
  color: #fff;
}
.card-info a:hover span {
  filter: brightness(10);
  opacity: 1;
}

@media (max-width: 750px) {
  /* .utility-info {
    text-align: center;
  }
  .utility-info ul {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  .utility-info li {
    width: 49%;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
  } */
}
@media screen and (max-width: 960px) {
  /* .utility-info li:last-of-type {
    width: 100%;
  } */
  .blog-card .card-info {
    opacity: 1;
    bottom: 100px;
  }
  .card-info {
    padding: 0 20px;
  }
  .blog-card:hover .title-content,
  .title-content {
    margin-top: 0px;
  }
  .blog-card {
    height: 550px;
  }
  .blog-card h3 {
    font-size: 1.3em;
  }
  .intro {
    font-size: 0.8em;
  }
}
@media (max-width: 500px) {
  .blog-card .card-info {
    opacity: 1;
    bottom: 100px;
  }
  .utility-info li:last-of-type {
    width: 100%;
  }
  .card-info {
    padding: 0 20px;
    /* opacity: 1;
    overflow: hidden;
     text-overflow: ellipsis; */
  }
  .blog-card:hover .title-content,
  .title-content {
    margin-top: 40px;
  }
  .blog-card {
    height: 550px;
  }
  .blog-card h3 {
    font-size: 1.3em;
  }
  .intro {
    font-size: 0.8em;
  }
}
