body {
  font-family: "Fira Code", monospace;
  /* background-image: url("../public/images/background-team.png"); */
  background-color: #f7f7f7;
}

h1 {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  margin-top: 50px;
}

.card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.card h2 {
  font-size: 18px;
  margin-top: 20px;
  color: #333;
}

.card p {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  padding: 0 20px;
  color: #333;
}

li {
  list-style: none;
}
@media (max-width: 425px) {
  /* targets phones in portrait mode */
}
@media (min-width: 426px) and (max-width: 768px) {
  /* targets phones in landscape mode and some small tablets */
}
@media (max-width: 768px) {
  /* targets larger tablets and laptops */
  .navbar i {
    font-size: x-large; /* increase this value to make the icons larger */
  }

  h1 {
    font-size: x-large;
  }
  h2 {
    font-size: large;
  }

  .homeBody .container {
    flex-direction: column;
  }

  .homeBody .container .homeCard {
    min-width: 100%;
  }
}

/* NavBar */
.navbar {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: px;
  margin-bottom: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 6;
}
.nabar container {
  display: flex;
  justify-content: flex-end;
}
.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin: 0 20px;
}

.navbar a {
  text-decoration: none;
  color: black;
  /* font-weight: bold; */
  transition: all 0.2s ease-in-out;
}

.navbar a:hover {
  color: #0079d3;
}
.navbar-left {
  display: flex;
  align-items: center;
  /* margin-right: auto; */
}

.navbar-left img {
  /* width: 50px;
  height: 50px; */
  /* width: min-content; */
  border-radius: 25px;
}
/* For the home cards */
.homeBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* min-height: 100vh; */
  /* z-index: -1; */
  /* z-index: 0; */
}

.homeBody .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 10px 0;
}

.homeBody .container .homeCard {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
  background: #232427;
}

.homeBody .container .homeCard:nth-child(1) .box .content a {
  background: #2196f3;
}

.homeBody .container .homeCard:nth-child(2) .box .content a {
  background: #e91e63;
}

.homeBody .container .homeCard:nth-child(3) .box .content a {
  background: #23c186;
}

.homeBody .container .homeCard .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.homeBody .container .homeCard .box:hover {
  transform: translateY(-50px);
}

.homeBody .container .homeCard .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.homeBody .container .homeCard .box .content {
  padding: 20px;
  text-align: center;
}

.homeBody .container .homeCard .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.homeBody .container .homeCard .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  /* z-index: -1; */
  transition: 0.5s;
  margin-bottom: 15px;
}

.homeBody .container .homeCard .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  /* z-index: -1; */
  transition: 0.5s;
}

.homeBody .container .homeCard .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.homeBody .container .homeCard .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.header {
  position: relative;
}
/* footer */
footer {
  background-color: #f2f2f2;
  /* position: sticky; */
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.copyright {
  margin: 0;
  color: #333;
}

footer .socialLinks {
  padding-left: 0.5%;
}

footer a {
  margin: 0 2px;
  text-decoration: none;
  color: #0b0080;
}
